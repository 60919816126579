<template>
  <div class="page flex-col">
    <div class="group_top flex-col">
      <div class="group_2 flex-col">
        <img :src="baseURL+folderPath" :class="['rotation-img',spimg]" :onerror="showDefaultImage">
        <navbar :activeName="activeName"/>
        <div class="prevNext-cls flex-row">
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="../assets/img/indexLeft.png"
            @click="rotationLeft"
          />
          <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="../assets/img/indexRight.png"
            @click="rotationRight"
          />
        </div>
        <div class="image-wrapper_5 flex-row ">
          <div class="flex-row" style="margin: 0 auto;">
            <div class="rotation-cls" v-for="(item,index) in rotationList" :key="index" :class="{'active-rotation':showRotation===index}"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="group_29 flex-col">
      <div class="section_label flex-row justify-between">
        <img
          class="image_split_left"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
        <div class="text-title-left-cls flex-col justify-between">
          <span class="title-english-text">ABOUT&nbsp;US</span>
          <div class="text-wrapper-cls">
            <span class="title-left-text">关于</span>
            <span class="title-right-text">我们</span>
          </div>
        </div>
        <img
          class="image_split_right"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
      </div>
      <div class="box_4 flex-col">
        <div class="box_14 flex-row justify-between">
          <div class="group_6 flex-col" :style="'background-image:url('+baseURL+company.pic+')'"></div>
          <div class="group_30 flex-col justify-between">
            <div class="text-group_18 flex-col justify-between">
              <span class="text_12">{{company.title | filterNull}}</span>
              <span class="text_13">{{company.content | filterNull}}</span>
            </div>
            <span class="company_desc_cls" v-html="company.remark" />
              <!-- {{company.remark | filterNull}} -->
            <!-- </span> -->
          </div>
        </div>
        <div class="box_15 flex-row justify-between">
          <div class="box_16 flex-col">
            <span class="brand-title">{{brand.title | filterNull}}</span>
            <span class="brand_desc_cls" v-html="brand.remark" />
              <!-- {{brand.remark | filterNull}}
            </span> -->
            <div class="more-btn-cls1 flex-col" @click="jump('/about')">查看更多</div>
          </div>
          <div class="group_10 flex-col" :style="'background-image:url('+baseURL+brand.pic+')'">
          </div>
        </div>
      </div>
      <div class="section_label flex-row justify-between">
        <img
          class="image_split_left"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
        <div class="text-title-left-cls flex-col justify-between">
          <span class="title-english-text">PRODUCT</span>
          <div class="text-wrapper-cls">
            <span class="title-left-text">产品</span>
            <span class="title-right-text">中心</span>
          </div>
        </div>
        <img
          class="image_split_right"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
      </div>
      <div class="section_category_div flex-row justify-around">
        <div class="section_category_item_div flex-col justify-between" v-for="(item,index) in categoryList" :key="index" @click="clickCategory(item)">
          <img
            class="image_category"
            referrerpolicy="no-referrer"
            :src="baseURL+item.pic"
            :onerror="showIconImage"
          />
          <span class="text-category" :class="{'active_category':activeCategory===item.name}">{{item.name}}</span>
        </div>
      </div>
      <div class="box_7 flex-col">
        <div class="box_18 flex-row justify-between">
          <img
            class="label_4"
            referrerpolicy="no-referrer"
            src="../assets/img/indexLeft.png"
            :class="{'disable-cls':productList.length<4}"
            @click="clickLeft"
          />
          <div class="grid_product flex-row">
            <div class="group_product_div flex-col" v-for="(item,index) in showProductList" :key="index" @click="clickProduct(item)">
              <div class="image-wrapper_2 flex-col">
                <img
                  class="image_product"
                  referrerpolicy="no-referrer"
                  :src="baseURL+item.pic"
                  :onerror="showErrorImage"
                />
              </div>
              <div class="group_product flex-row">
                <div class="section_product flex-col"></div>
                <div class="group_product_text_div flex-col justify-between">
                  <span class="product_name_cls">{{item.title}}</span>
                  <span class="product_desc_cls">{{item.content}}</span>
                </div>
              </div>
            </div>
          </div>
          <img
            class="product_icon_right"
            referrerpolicy="no-referrer"
            src="../assets/img/indexRight.png"
            :class="{'disable-cls':productList.length<4}"
            @click="clickRight"
          />
        </div>
        <div class="box_more flex-row">
          <div class="more-btn-cls flex-col" @click="jump('/productService')">查看更多</div>
        </div>
      </div>
      <div class="section_label flex-row justify-between">
        <img
          class="image_split_left"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
        <div class="text-title-left-cls flex-col justify-between">
          <span class="title-english-text">NEWS</span>
          <div class="text-wrapper-cls">
            <span class="title-left-text">新闻</span>
            <span class="title-right-text">动态</span>
          </div>
        </div>
        <img
          class="image_split_right"
          referrerpolicy="no-referrer"
          src="../assets/img/splite.png"
        />
      </div>
      <div class="section_13 flex-row justify-between">
        <div class="block_1 flex-col"><div class="box_11 flex-col"></div></div>
        <div class="group_31 flex-col">
          <div class="flex-col" v-for="(item,index) in newsList" :key="index" @click="clickNews(item)">
            <div class="block_3 flex-row justify-between">
              <div class="text-wrapper_17 flex-col justify-between">
                <span class="news_day">{{item.day | filterNull}}</span>
                <span class="news_month">{{item.date | filterNull}}</span>
              </div>
              <div class="text-group_24 flex-col justify-between">
                <span class="news_title">{{item.title | filterNull}}</span>
                <span class="news_content">{{item.content | filterNull}}</span>
              </div>
            </div>
            <div class="group_19 flex-col"></div>
          </div>
          <div class="more-btn-cls flex-col" @click="jump('/news')">查看更多</div>
        </div>
      </div>
      <bottom :activeName="activeName"/>
    </div>
  </div>
</template>
<script>
import navbar from '../components/navbar.vue';
import bottom from '../components/bottom.vue';
import { getCompanyData, getTypeList, getPicList } from '@/api/home'

export default {
  components: { navbar,bottom },
  data() {
    return {
      baseURL: this.$apis,
      activeName:'首页',
      constants: {},
      newsList:[],
      productList:[],
      showProductList:[],
      categoryList:[],
      rotationList:[],
      activeCategory:'锂电池',
      company:{
        title:'',
        content:'',
        remark:''
      },
      brand:{
        title:'',
        remark:''
      },
      showIndex:0,
      showRotation:0,
      folderPath:'',
      spimg:'',
      interval: null
    };
  },
  computed: {
    showErrorImage() {
      return 'this.src="' + require('@/assets/img/productImg.png') + '"'
    },
    showDefaultImage() {
      return 'this.src="' + require('@/assets/img/index1.jpg') + '"'
    },
    showIconImage() {
      return 'this.src="' + require('@/assets/img/indexIcon1.png') + '"'
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created(){
    this.initData()
  },
  filters:{
    filterNull(val){
      if(val){
        return val
      }else{
        return ''
      }
    }
  },
  methods: {
    initData(){
      let that = this
      function rotationToMove() {
        if (that.showRotation == that.rotationList.length - 1) {
          that.showRotation = 0;
        } else {
          that.showRotation++;
          that.folderPath = that.rotationList[that.showRotation].folderPath
            ? that.rotationList[that.showRotation].folderPath
            : "";
          that.foldertext = that.rotationList[that.showRotation].remark
            ? that.rotationList[that.showRotation].remark
            : "";
          that.spimg = "sp_img";
          setTimeout(() => {
            that.spimg = "";
          }, 4000);
        }
      }
      this.getData({callName:'crobo'},'company')
      this.getData({callName:'xjr'},'brand')
      this.getData({type:'news'},'news')
      getTypeList({type:'product'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let categoryList = []
          res.rows.forEach(element => {
            if(element.status==='0'){
              categoryList.push(element)
            }
          });
          this.categoryList= categoryList
          if(categoryList[0]){
            this.activeCategory = categoryList[0].name
            let obj={
              type:'product',
              status:'0',
              contain: categoryList[0].id
            }
            this.getData(obj,'product')
          }
        }
      })
      getTypeList({type:'company',status:0,name:'公司相关介绍'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let data = res.rows[0]
          if(data.seoKey){
            document.querySelector('meta[name="keywords"]').setAttribute('content',data.seoKey)
          }
          if(data.seoDesc){
            document.querySelector('meta[name="description"]').setAttribute('content',data.seoDesc)
          }
        }
      })
      getPicList({docsName:'首页轮播'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          console.log(res)
          this.rotationList= res.rows
          if(this.rotationList[0]){
            this.folderPath = this.rotationList[0].folderPath ? this.rotationList[0].folderPath : ''
          }
          this.interval = setInterval(rotationToMove, 15000);
        }
      })
    },
    getData(params,type){
      getCompanyData(params).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          switch(type){
            case 'company':
              if(res.rows && res.rows.length>0){
                this.company = res.rows[0]
                // this.company.remark = this.company.remark.replace(/<[^>]*>/g, '')
              }
              break;
            case 'brand':
              if(res.rows && res.rows.length>0){
                if(res.rows[0]){
                  this.brand = res.rows[0]
                }else{
                  this.brand = {
                    pic: '',
                    title:'',
                    remark:''
                  }
                }
                // this.brand.remark = this.brand.remark.replace(/<[^>]*>/g, '')
              }
              break;
            case 'product':
              this.productList = []
              if(res.rows && res.rows.length>0){
                res.rows.forEach((item)=>{
                  item.content = item.content ? item.content.replace(/<[^>]*>/g, ''):''
                  this.productList.push(item)
                })
                this.showIndex=0
                let productList = JSON.parse(JSON.stringify(this.productList))
                this.showProductList = productList.slice(0,3)
              }
              break;
            case 'news':
              if(res.rows && res.rows.length>0){
                this.newsList = []
                res.rows.forEach((item,index)=>{
                  if(index<4){
                    let date = item.createTime.split(" ")
                    item.date = date[0].slice(0,7)
                    item.day = date[0].slice(8,10)
                    this.newsList.push(item)
                  }
                })
              }
          }
          
        }
      })
    },
    clickProduct(item){
      this.$router.push({
          path: 'productService/productServiceDetail?id='+item.id
      })
    },
    clickNews(item){
      this.$router.push({
          path: 'news/newsDetail?id='+item.id
      })
    },
    clickLeft(){
      if(this.showIndex>0){
        this.showIndex--
        let productList = JSON.parse(JSON.stringify(this.productList))
        this.showProductList = productList.slice(this.showIndex, this.showIndex+3)
      }
    },
    clickRight(){
      console.log(this.showIndex)
      if(this.showIndex<(this.productList.length-3)){
        this.showIndex++
        console.log(this.showIndex,"ind")
        let productList = JSON.parse(JSON.stringify(this.productList))
        this.showProductList = productList.slice(this.showIndex, this.showIndex+3)
      }
    },
    clickCategory(item){
      this.activeCategory = item.name
      let obj={
        type:'product',
        status:'0',
        contain: item.id
      }
      this.getData(obj, 'product')
    },
    jump(url){
      this.$router.push({
          path: url
      })
    },
    rotationLeft(){
      if(this.showRotation>0){
        this.showRotation--
        this.folderPath = this.rotationList[this.showRotation].folderPath ? this.rotationList[this.showRotation].folderPath : ''
        this.spimg= 'sp_img'
        setTimeout(() => {
          this.spimg= ''
        }, 4000);
      }
    },
    rotationRight(){
      if(this.showRotation<(this.rotationList.length-1)){
        this.showRotation++
        this.folderPath = this.rotationList[this.showRotation].folderPath ? this.rotationList[this.showRotation].folderPath : ''
        this.spimg= 'sp_img'
        setTimeout(() => {
          this.spimg= ''
        }, 4000);
      }
    }
  }
};
</script>
<style scoped lang="css">
.page {
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.group_top {
  /* background-image: url(../assets/img/index1.png);
  background-size: 100% 100%; */
  width: 100%;
}

.group_2 {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;
}

.section_1 {
  background-color: rgba(0, 0, 0, 0.4);
  height: 80px;
  width: 100%;
}

.section_7 {
  width: 1019px;
  height: 44px;
  margin: 17px 0 0 208px;
}

.label_1 {
  width: 40px;
  height: 40px;
  margin-top: 3px;
}

.text-wrapper_16 {
  width: 154px;
  height: 44px;
  margin-left: 8px;
}

.text_1 {
  width: 154px;
  height: 30px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 22px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
}

.text_2 {
  width: 144px;
  height: 14px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text_3 {
  width: 28px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(249, 213, 154, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 340px;
}

.text_4 {
  width: 70px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(191, 191, 191, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 38px;
}

.text_5 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(191, 191, 191, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 24px;
}

.text_6 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(191, 191, 191, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 31px;
}

.text_7 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(191, 191, 191, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 31px;
}

.text_8 {
  width: 56px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(191, 191, 191, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  margin: 13px 0 0 31px;
}

.section_8 {
  width: 28px;
  height: 4px;
  margin: 15px 0 0 750px;
}

.group_3 {
  background-color: rgba(249, 213, 154, 1);
  width: 28px;
  height: 4px;
}

.image-wrapper_5 {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 50px;
}

.image_1 {
  width: 70px;
  height: 10px;
  margin-top: 40px;
}

.label_2 {
  width: 48px;
  height: 48px;
}

.label_3 {
  width: 48px;
  height: 48px;
  margin-left: 10px;
}

.group_29 {
  width: 100%;
  margin-bottom: 1px;
}

.section_label {
  width: 248px;
  height: 78px;
  margin: 80px auto 0 auto;
}

.section_category_div{
  width: 50%;
  margin: 50px auto 30px auto;
}

.text-wrapper-cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin-left: 1px;
}

.box_4 {
  background-image: url(../assets/img/indexBg.png);
  background-size: 100% 100%;
  margin-top: 50px;
  width: 100%;
}

.box_14 {
  width: 1024px;
  height: 335px;
  margin: 30px auto 0 auto;
}

.group_6 {
  background-image: url(../assets/img/index2.png);
  background-size: 100% 100%;
  width: 502px;
}

.group_30 {
  width: 492px;
  height: 300px;
}

.text-group_18 {
  width: 364px;
  height: 70px;
}

.text_12 {
  width: 364px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
}

.text_13 {
  width: 297px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 10px;
}

.company_desc_cls {
  width: 492px;
  height: 200px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box_15 {
  width: 1024px;
  height: 288px;
  margin: 0 auto 40px auto;
}

.box_16 {
  width: 472px;
  height: 258px;
  margin-top: 30px;
}

.brand-title {
  width: 196px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 276px;
}

.brand_desc_cls {
  width: 472px;
  height: 120px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.more-btn-cls1 {
  background-color: rgba(222, 187, 129, 1);
  height: 40px;
  width: 148px;
  margin: 28px 0 0 auto;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  cursor: pointer;
}

.group_10 {
  background-image: url(../assets/img/index5.png);
  background-size: 100% 100%;
  width: 522px;
}

.image_split_left {
  width: 30px;
  height: 1px;
  margin-top: 55px;
}

.text-group_19 {
  width: 128px;
  height: 78px;
  margin-left: 30px;
}

.title-english-text {
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin-left: 2px;
}

.image_split_right {
  width: 30px;
  height: 1px;
  margin: 55px 0 0 30px;
}

.section_category_item_div {
  height: 120px;
  text-align: center;
}

.image_category {
  width: 67px;
  height: 67px;
  margin: 0 auto;
  cursor: pointer;
}

.text-group_4 {
  width: 72px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(207, 162, 94, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin-top: 20px;
}

.box_17 {
  width: 67px;
  height: 120px;
}

.text-wrapper_5 {
  background-color: rgba(216, 216, 216, 1);
  height: 67px;
  width: 67px;
}

.text_21 {
  width: 48px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin: 17px 0 0 9px;
}

.text-category {
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin: 20px 0 0 1px;
}

.active_category{
  color: rgba(207, 162, 94, 1);
}

.box_7 {
  background-image: url(../assets/img/index3.png);
  background-size: 100% 100%;
  margin-top: 30px;
  width: 100%;
}

.box_18 {
  width: 1024px;
  height: 388px;
  margin: 50px auto 0 auto;
}

.label_4 {
  width: 48px;
  height: 48px;
  margin-top: 170px;
  cursor: pointer;
}

.grid_product {
  width: 890px;
  height: 388px;
  margin-left: 39px;
  flex-wrap: wrap;
}

.image-wrapper_2 {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 1);
  height: 302px;
  margin-right: 35px;
  width: 260px;
}

.image-wrapper_2:nth-child(3n) {
  margin-right: 0;
}
.image-wrapper_2:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

.image_product {
  width: 258px;
  margin: auto 0;
}

.group_product_div{
  cursor: pointer;
}

.group_product {
  background-color: rgba(0, 0, 0, 0.4);
  width: 260px;
  height: 86px;
  margin-right: 35px;
}

.group_product_div:hover .group_product{
  background-color: rgba(207, 162, 94, 1);
}

.section_product {
  background-color: rgba(255, 200, 0, 1);
  width: 5px;
  height: 17px;
  margin-top: 22px;
}

.group_product_text_div {
  width: 100%;
  height: 46px;
  padding: 19px 14px 0 14px;
}

.product_name_cls {
  width: 100%;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_desc_cls {
  width: 100%;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product_icon_right {
  width: 48px;
  height: 48px;
  margin: 170px 0 0 0px;
  cursor: pointer;
}

.box_more {
  width: 154px;
  height: 48px;
  margin: 50px auto 60px auto;
}

.text-title-left-cls {
  margin-left: 30px;
}

.title-left-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(207, 162, 94, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}

.title-right-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}

.section_13 {
  width: 1024px;
  height: 435px;
  margin: 50px auto 0 auto;
}

.block_1 {
  background-image: url(../assets/img/index4.jpg);
  background-size: 100% 100%;
  width: 435px;
}

.box_11 {
  background-color: rgba(0, 0, 0, 0.2);
  width: 435px;
  height: 435px;
}

.group_31 {
  width: 522px;
  height: 435px;
}

.block_3 {
  width: 522px;
  height: 57px;
  cursor: pointer;
}

.text-wrapper_17 {
  width: 52px;
  height: 57px;
}

.text_33 {
  width: 34px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 28px;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 9px;
}

.text_34 {
  width: 52px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.text-group_24 {
  width: 450px;
  height: 57px;
}

.news_title {
  width: 450px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
}

.text_36 {
  width: 448px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 12px;
}

.group_19 {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  width: 522px;
  height: 1px;
  margin-top: 19px;
  margin-bottom: 19px;
}

.block_4 {
  width: 522px;
  height: 57px;
  margin-top: 20px;
}

.text-group_25 {
  width: 52px;
  height: 57px;
}

.news_day {
  width: 34px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 28px;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 9px;
}

.news_month {
  width: 52px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.text-group_26 {
  width: 450px;
  height: 57px;
}

.text_39 {
  width: 450px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.news_content {
  width: 450px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group_21 {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  width: 522px;
  height: 1px;
  margin-top: 19px;
}

.block_5 {
  width: 522px;
  height: 57px;
  margin-top: 20px;
}

.text-wrapper_18 {
  width: 52px;
  height: 57px;
}

.text_41 {
  width: 34px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 28px;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 9px;
}

.text_42 {
  width: 52px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.text-group_27 {
  width: 450px;
  height: 57px;
}

.text_43 {
  width: 450px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_44 {
  width: 448px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 12px;
}

.group_23 {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  width: 522px;
  height: 1px;
  margin-top: 19px;
}

.block_6 {
  width: 522px;
  height: 57px;
  margin-top: 20px;
}

.text-wrapper_19 {
  width: 52px;
  height: 57px;
}

.text_45 {
  width: 34px;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 28px;
  text-align: center;
  white-space: nowrap;
  line-height: 40px;
  margin-left: 9px;
}

.text_46 {
  width: 52px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  line-height: 17px;
}

.text-group_28 {
  width: 450px;
  height: 57px;
}

.text_47 {
  width: 450px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}

.text_48 {
  width: 448px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 12px;
}

.group_25 {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  width: 522px;
  height: 1px;
  margin-top: 19px;
}

.more-btn-cls {
  background-color: rgba(222, 187, 129, 1);
  height: 48px;
  width: 154px;
  color: rgba(51, 51, 51, 1);
  text-align: center;
  font-size: 16px;
  line-height: 48px;
  cursor: pointer;
}

.text_49 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin: 13px 0 0 45px;
}
.disable-cls{
  cursor: not-allowed;
}
.rotation-cls{
  border-radius:50%;
  background: #999;
  width: 10px;
  height: 10px;
  margin:0 5px;
}
.active-rotation{
  background: #fff;
}
.prevNext-cls{
  height: 50px;
  position: absolute;
  bottom: 80px;
  right: 15%;
  width: auto;
}
.rotation-img{
  width: 100%;
  height: 950px;
  object-fit: cover;
  opacity: 1;  
  transition: opacity 5s;  
}
.sp_img{
  animation: slideShow 5s infinite;
}
.slideShow {  
  position: relative;  
  height: 300px;  
  width: 500px;  
}  
@keyframes slideShow {  
  0% { opacity: 0.3; }  
  100% { opacity: 1; }  
}
</style>